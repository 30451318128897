<!--
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2021-11-06 12:21:56
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-11-06 17:22:24
-->
<!--  -->
<template>
  <div class="mh">
    <router-view />
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang='scss' scoped>

</style>
